<i18n>
{
  "de": {
    "addButton": "Wärmenetz hinzufügen",
    "idTitle": "Identifikator",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "pageTitle": "Wärmenetze",
    "confirmDelete": "Wollen Sie dieses Wärmenetz wirklich löschen? Falls noch Liegenschaften damit verknüpft sind werden diese auf Standard-Fernwärmemix zurückgesetzt.",
    "nBuildingsTitle": "Anzahl Gebäude",
    "noGrids": "In diesem Portfolio sind noch keine Wärmenetze vorhanden."
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady" class="heat-grids-page">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <detail-list class="grids-table" v-if="portfolio.heat_grids.length">
        <template #header>
          <span>{{ $t('idTitle') }}</span>
          <span>{{ $t('nameTitle') }}</span>
          <span>{{ $t('nBuildingsTitle') }}</span>
          <span></span>
        </template>
        <li class="heat-grid-row" v-for="(heat_grid, index) in portfolio.heat_grids" v-bind:key="index">
          <span>{{ heat_grid.identifier }}</span>
          <span>{{ heat_grid.name }}</span>
          <span>{{ heat_grid.n_buildings }}</span>
          <list-button-wrapper>
            <list-edit-button v-if="portfolio.permissions.EDIT_HEAT_GRIDS" @click="editGrid = heat_grid; editModalOpen = true;" />
            <list-delete-button  v-if="portfolio.permissions.EDIT_HEAT_GRIDS" @click="onDelete(heat_grid)" />
          </list-button-wrapper>
        </li>
      </detail-list>
      <div v-else>
        {{ $t('noGrids') }}
      </div>
      <button-wrapper>
        <a class="button" v-if="portfolio.permissions.EDIT_HEAT_GRIDS" href="#" @click.prevent="editGrid = null; editModalOpen = true;">{{ $t('addButton') }}</a>
      </button-wrapper>
      <edit-heat-grid-modal :portfolio="portfolio" :heat-grid="editGrid" v-if="editModalOpen" @close="editModalOpen = false" />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import DetailList from '@/components/shared/lists/DetailList'
import EditHeatGridModal from '@/components/settings/heat_grids/EditHeatGridModal'
import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  data: function () {
    return {
      editGrid: null,
      editModalOpen: false
    }
  },
  methods: {
    ...mapActions({
      deleteHeatGrid: 'portfolio/deleteHeatGrid'
    }),
    async onDelete (grid) {
      if (confirm(this.$t('confirmDelete'))) {
        try {
          await this.deleteHeatGrid(grid)
          this.$emit('close')
        } catch (error) {
          this.error = error
        }
      }
    }
  },
  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    MainLayout,
    DetailList,
    EditHeatGridModal
  }
}
</script>

<style>
.heat-grids-page .grids-table.detail-list > ul > li {
  grid-template-columns: 200px 1fr 160px 120px;
}
</style>
