<i18n>
{
  "de": {
    "nameTitle": "Name",
    "remarksTitle": "Bemerkungen",
    "heatersTitle": "Wärmeerzeugung",
    "gridEfficiencyTitle": "Nutzungsgrad"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-heat-grid">
    <form-row :label="$t('nameTitle')" v-if="model.name || isEditing">
      <text-input v-model="model.name" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('remarksTitle')" v-if="model.remarks || isEditing">
      <text-input v-model="model.remarks" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('gridEfficiencyTitle')">
      <numeric-input v-model="model.energy_prices.electricity.grid" :units="''" :edit="isEditing" />
    </form-row>
    <edit-grid-heaters-table
      v-model="model.heaters"
      :edit="isEditing"
      @input="onHeatersChange"
      @validation="onValidation($event, 'heaters-table')"
    />
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import EditGridHeatersTable from '@/components/settings/heat_grids/EditGridHeatersTable'
import TextInput from '@/components/shared/forms/TextInput'
import FormRow from '@/components/shared/forms/FormRow'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    heatGrid: {
      type: Object
    }
  },
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  watch: {
    heatGrid: function () {
      this.resetModel()
    }
  },
  created: function () {
    if (!this.heatGrid) {
      // Start editing immediately when adding a new grid
      this.onFormEvent('edit')
    }
    this.resetModel()
  },
  methods: {
    ...mapActions({
      addHeatGrid: 'portfolio/addHeatGrid',
      updateHeatGrid: 'portfolio/updateHeatGrid'
    }),
    resetModel () {
      if (this.heatGrid) {
        this.model = {
          portfolio_id: this.heatGrid.portfolio_id,
          name: this.heatGrid.name,
          remarks: this.heatGrid.remarks,
          grid_efficiency: this.heatGrid.grid_efficiency,
          heaters: JSON.parse(JSON.stringify(this.heatGrid.heaters))
        }
      } else {
        this.buildings = null
        this.model = {
          portfolio_id: this.portfolio.id,
          name: null,
          remarks: null,
          grid_efficiency: 0.85,
          heaters: [{
            'type': 'UNKNOWN',
            'share': 100,
            'ghg_factor': null,
            'pe_factor': null,
            'energy_calibration': null
          }]
        }
      }
    },
    async saveHandler () {
      if (this.heatGrid) {
        // Update existing heat grid
        await this.updateHeatGrid({ id: this.heatGrid.id, heatGrid: this.model })
      } else {
        // Create new heat grid
        await this.addHeatGrid(this.model)
      }
    },
    onHeatersChange (heaters) {
      this.model.heaters = heaters
    }
  },
  computed: {
    totalShare () {
      return this.model.heaters.reduce((pv, cv) => pv + cv.share, 0)
    }
  },
  components: {
    EditContainer,
    EditGridHeatersTable,
    FormRow,
    TextInput
  }
}
</script>
<style>
.edit-heat-grid .limit-width {
  max-width: 150px;
  display: inline-block;
}
.edit-heat-grid .select-width {
  min-width: 150px;
}
</style>
