<!--
Edit form for GridHeaterSchema (many=true)
TODO: handle validation of share and notify parent on error #refactoring
-->
<i18n>
{
  "de": {
    "heatingTypeTitle": "Typ",
    "shareTitle": "Anteil",
    "energyCalibration": "Endenergie Kalibrierung",
    "addHeatingButton": "Wärmeerzeuger hinzufügen",
    "emissionFactorTitle": "Emissionsfaktor",
    "peFactorTitle": "Primärenergiefaktor",
    "noHeatingDefined": "Keine Wärmeerzeuger",
    "totalShareWarning": "Summe Anteil muss exakt 100% betragen!",
    "total": "Total",
    "shareInfoBoxTitle": "Anteil",
    "shareInfoBox": "Anteil der Wärme, der durch diesen Wärmeerzeuger bereitgestellt wird.",
    "ghgFactorInfoBoxTitle": "GHG-Emissionsfaktor",
    "ghgFactorInfoBox": "GHG-Emissionen pro verbrauchte Endenergiemenge (netto gelieferte Energie, z.B. Brennwert von Heizöl). Wenn dieses Feld leergelassen wird, werden die Standardwerte aus den Portfolioeinstellungen verwendet. Für Wärmepumpen und Elektroheizungen wird der Strommix berücksichtigt.",
    "peFactorInfoBoxTitle": "Primärenergiefaktor",
    "peFactorInfoBox": "Verbrauchte Primärenergie pro verbrauchte Endenergiemenge (netto gelieferte Energie, z.B. Brennwert von Heizöl). Wenn dieses Feld leergelassen wird, werden die Standardwerte aus den Portfolioeinstellungen verwendet. Für Wärmepumpen und Elektroheizungen wird der Strommix berücksichtigt."
  }
}
</i18n>

<template>
  <div class="edit-grid-heaters-table">
    <detail-list class="heating-table" v-if="model.length > 0">
      <template #header>
        <span>{{ $t('heatingTypeTitle') }}</span>
        <span class="align-right">{{ $t('shareTitle') }} <info-box :title="$t('shareInfoBoxTitle')">{{ $t('shareInfoBox') }}</info-box></span>
        <!-- <span class="align-right">{{ $t('endEnergyTitle') }} <info-box :title="$t('endEnergyInfoBoxTitle')">{{ $t('endEnergyInfoBox') }}</info-box></span> -->
        <!-- <span class="align-right">{{ $t('energyCalibration') }} <info-box :title="$t('calibrationInfoBoxTitle')">{{ $t('calibrationInfoBox') }}</info-box></span> -->
        <!-- <span class="align-right">{{ $t('energyCalibrationFactor') }}</span> -->
        <span class="align-right">{{ $t('emissionFactorTitle') }} <info-box :title="$t('ghgFactorInfoBoxTitle')">{{ $t('ghgFactorInfoBox') }}</info-box></span>
        <span class="align-right">{{ $t('peFactorTitle') }} <info-box :title="$t('peFactorInfoBoxTitle')">{{ $t('peFactorInfoBox') }}</info-box></span>
        <span v-if="isEditing"></span>
      </template>
      <li v-for="(h, index) in model" :key="index">
        <heating-type-picker :exclude="['LOCAL']" :edit="isEditing" v-model="h.type" @input="onInput" />
        <div class="align-right">
          <numeric-input @input="onInput" v-model="h.share" :min="0" :max="100" :edit="isEditing" :units="'%'" />
        </div>
        <!-- <div class="align-right">
          <span v-if="getEndEnergy(index)">
            {{ formatNumber(getEndEnergy(index), -2) }} kWh
          </span>
          <span v-else>-</span>
        </div> -->
        <!-- <div class="align-right">
          <numeric-input @input="onInput" v-model="h.energy_calibration" optional :min="0" :edit="isEditing" :units="'kWh'" />
        </div> -->
        <!--
        <div class="align-right">
          <span v-if="getCalibrationFactor(index) && h.energy_calibration">
            <span v-if="getCalibrationFactor(index) >= 1.0">
              <data-warning v-if="getCalibrationFactor(index) > 1.667" :title="$t('highCalibrationFactorWarningTitle')"><span v-html="$t('highCalibrationFactorWarning')" /></data-warning>
              +{{ formatNumber(getCalibrationFactor(index) * 100 - 100, 1) }} %
            </span>
            <span v-else>
              <data-warning v-if="getCalibrationFactor(index) < 0.6" :title="$t('lowCalibrationFactorWarningTitle')"><span v-html="$t('lowCalibrationFactorWarning')" /></data-warning>
              {{ formatNumber(getCalibrationFactor(index) * 100 - 100, 1) }} %
            </span>
          </span>
          <span v-else>-</span>
        </div>
        -->
        <div class="align-right">
          <numeric-input v-if="isEditing && h.type === 'DISTRICT'" @input="onInput" optional v-model="h.ghg_factor" :min="0" :edit="isEditing" :units="'kg/kWh'" />
          <div v-else-if="heatersResults && heatersResults[index]">
            {{ formatNumber(heatersResults[index].ghg_factor.value, 3) }} kg/kWh
            <div class="source-info" v-if="heatersResults[index].ghg_factor === 'DEFAULT'">{{ $t('factorSourceDefault')}}</div>
            <div class="source-info" v-if="heatersResults[index].ghg_factor === 'GIS_ENERGY_GRID'">{{ $t('factorSourceEnergyGrid')}}</div>
          </div>
          <span v-else-if="!isEditing && h.ghg_factor !== null">{{ h.ghg_factor }} kg/kWh</span>
          <div v-else>-</div>
        </div>
        <div class="align-right">
          <numeric-input v-if="isEditing && h.type === 'DISTRICT'" @input="onInput" optional v-model="h.pe_factor" :min="0" :edit="isEditing" :units="''" />
          <div v-else-if="heatersResults && heatersResults[index]">
            {{ formatNumber(heatersResults[index].pe_factor.value, 3) }}
            <div class="source-info" v-if="heatersResults[index].pe_factor === 'DEFAULT'">{{ $t('factorSourceDefault')}}</div>
            <div class="source-info" v-if="heatersResults[index].pe_factor === 'GIS_ENERGY_GRID'">{{ $t('factorSourceEnergyGrid')}}</div>
          </div>
          <span v-else-if="!isEditing && h.pe_factor !== null">{{ h.pe_factor }}</span>
          <div v-else>-</div>
        </div>
        <list-button-wrapper>
          <list-delete-button v-if="isEditing" @click="onDeleteHeating(index)" />
        </list-button-wrapper>
      </li>
      <template #footer v-if="model.length > 1">
        <span>{{ $t('total') }}</span>
        <span class="align-right">{{ totalShare}} %</span>
        <!-- <span class="align-right" v-if="totalEndEnergy">{{ formatNumber(totalEndEnergy, -2) }} kWh</span>
        <span class="align-right" v-else>-</span> -->
        <!-- <span class="align-right" v-if="totalCalibration">{{ formatNumber(totalCalibration, 0) }} kWh</span>
        <span class="align-right" v-else>-</span> -->
        <!-- <span></span> -->
        <span></span>
      </template>
    </detail-list>
    <div v-else>
      {{ $t('noHeatingDefined') }}
    </div>
    <div class="sum-warning" v-if="model.length > 0 && totalShare !== 100">
      <p>{{ $t('totalShareWarning') }}</p>
    </div>
    <button-wrapper>
      <button class="button" @click.prevent="onAddHeating" v-if="isEditing">{{ $t('addHeatingButton') }}</button>
    </button-wrapper>
  </div>
</template>
<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import HeatingTypePicker from '@/components/building/edit/HeatingTypePicker'
import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'
// import DataWarning from '@/components/shared/DataWarning'
import InfoBox from '@/components/shared/InfoBox'

export default {
  mixins: [ComplexFormMixin],
  props: {
    heatersResults: {
      type: Array
    }
  },
  computed: {
    // Overrides mixin function
    isValid () {
      return this.noInvalidFields && (this.model.length === 0 || (this.totalShare === 100))
    },
    totalShare () {
      return this.model.reduce((pv, cv) => pv + cv.share, 0)
    },
    /* totalEndEnergy () {
      if (this.heatersResults) {
        return this.heatersResults.reduce((pv, cv) => pv + cv.rh_end_energy + cv.dhw_end_energy, 0)
      }
      return undefined
    }, */
    totalCalibration () {
      return this.model.reduce((pv, cv) => pv + cv.energy_calibration, 0)
    }
  },
  methods: {
    /* getCalibrationFactor (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].calibration_factor !== null) {
        return this.heatersResults[heaterIdx].calibration_factor
      }
      return undefined
    },
    getEndEnergy (heaterIdx) {
      if (this.heatersResults && this.heatersResults[heaterIdx] !== undefined && this.heatersResults[heaterIdx].rh_end_energy !== null) {
        return (this.heatersResults[heaterIdx].rh_end_energy + this.heatersResults[heaterIdx].dhw_end_energy)
      }
      return undefined
    }, */
    onAddHeating () {
      this.model.push({
        'type': 'UNKNOWN',
        'share': this.model.length === 0 ? 100 : 0,
        'ghg_factor': null,
        'pe_factor': null,
        'energy_calibration': null
      })
      this.onInput()
    },
    onDeleteHeating (index) {
      this.model.splice(index, 1)
      this.onInput()
    }
  },
  components: {
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    NumericInput,
    DetailList,
    InfoBox,
    // DataWarning,
    HeatingTypePicker
  }
}
</script>
<style>
.edit-grid-heaters-table .detail-list > ul > li {
  grid-template-columns: 2fr 1fr 1fr 1fr 50px;
}
.edit-grid-heaters-table .sum-warning {
  color: var(--warning-color);
  padding: var(--box-padding);
}
.edit-grid-heaters-table .align-right {
  text-align: right;
}
.edit-grid-heaters-table .source-info {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
}
</style>
