<!--
Modal Wrapper for EditHeatGrid
-->
<i18n>
{
  "de": {
    "editTitle": "Wärmenetz bearbeiten",
    "createTitle": "Wärmenetz erstellen",
    "buildingsTitle": "Aktuell angeschlossene Liegenschaften",
    "identifierTitle": "Nr.",
    "total": "Total",
    "descriptionTitle": "Bezeichnung",
    "endEnergyTitle": "Endenergiebedarf"
  }
}
</i18n>
<template>
  <modal @close="$emit('close', $event)" wide class="edit-heat-grid-modal">
    <template v-slot:header>
      <h1 v-if="!heatGrid">{{ $t('createTitle') }}</h1>
      <h1 v-else>{{ $t('editTitle') }}</h1>
    </template>
    <edit-heat-grid
      :portfolio="portfolio"
      :allow-edit="portfolio.permissions.EDIT_HEAT_GRIDS"
      :heat-grid="heatGrid"
      @save="onSave"
      @cancel="onCancel"
    />
    <br /><br />

    <h2 v-if="buildings">{{ $t('buildingsTitle') }}</h2>
    <detail-list class="buildings-table" v-if="buildings">
      <template #header>
        <span>{{ $t('identifierTitle') }}</span>
        <span>{{ $t('descriptionTitle') }}</span>
        <span class="align-right">{{ $t('endEnergyTitle') }}</span>
      </template>
      <li v-for="(building, index) in buildings" v-bind:key="index">
        <span>{{ building.identifier }}</span>
        <span>{{ building.description }}</span>
        <span class="align-right">{{ formatNumber(getEndEnergy(building) / 1000, 1) }} MWh</span>
      </li>
      <template #footer>
        <span>{{ $t('total') }}</span>
        <span></span>
        <span class="align-right">{{ formatNumber(totalEndEnergy / 1000, 1) }} MWh</span>
      </template>
    </detail-list>
  </modal>
</template>

<script>
import compassApi from '@/services/compass-api'

import EditHeatGrid from '@/components/settings/heat_grids/EditHeatGrid'
import DetailList from '@/components/shared/lists/DetailList'
import Modal from '@/components/shared/Modal'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    heatGrid: { // edit existing heat grid
      type: Object
    }
  },
  data: function () {
    return {
      buildings: null
    }
  },
  components: {
    Modal,
    EditHeatGrid,
    DetailList
  },
  computed: {
    totalEndEnergy () {
      return this.buildings && this.buildings.reduce((pv, cv) => pv + this.getEndEnergy(cv), 0)
    }
  },
  watch: {
    heatGrid () {
      this.getBuildings()
    }
  },
  created () {
    this.getBuildings()
  },
  methods: {
    async getBuildings () {
      if (this.heatGrid) {
        this.buildings = await compassApi.call(`/heat_grids/${this.heatGrid.id}/buildings/today`)
      } else {
        this.buildings = null
      }
    },
    getEndEnergy (building) {
      return building.heating.filter(h => h.grid_id === this.heatGrid.id).reduce((pv, cv) => pv + cv.end_energy * cv.calibration_factor, 0)
    },
    onCancel () {
      if (this.heatGrid === null) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },
    onSave () {
      if (this.heatGrid === null) {
        // Close dialog if adding new is saved
        this.$emit('close')
      }
    }
  }
}
</script>
<style>
.edit-heat-grid-modal .detail-list.buildings-table > ul > li {
  grid-template-columns: 1fr 4fr 1fr 2fr
}
.edit-heat-grid-modal .align-right {
  text-align: right;
}
</style>
